export const TYPE_RELOAD_TRIPLE_TABLE = 'TYPE_RELOAD_TRIPLE_TABLE';
export const TYPE_RELOAD_WALLET_TABLE = 'TYPE_RELOAD_WALLET_TABLE';
export const TYPE_RELOAD_ACTIVE_TABLE = 'TYPE_RELOAD_ACTIVE_TABLE';
export const TYPE_RELOAD_TRADING_TABLE = 'TYPE_RELOAD_TRADING_TABLE';

export const actionReloadTripleTable = () => ({
  type: TYPE_RELOAD_TRIPLE_TABLE,
});
export const actionReloadWalletTable = () => ({
  type: TYPE_RELOAD_WALLET_TABLE,
});
export const actionReloadActiveTable = () => ({
  type: TYPE_RELOAD_ACTIVE_TABLE,
});
export const actionReloadTradingTable = () => ({
  type: TYPE_RELOAD_TRADING_TABLE,
});
