export const TYPE_DASHBORAD_TOGGLE_MARKETS = 'TYPE_DASHBORAD_TOGGLE_MARKETS';
export const TYPE_DASHBORAD_TOGGLE_ALERTS = 'TYPE_DASHBORAD_TOGGLE_ALERTS';
export const TYPE_DASHBORAD_TOGGLE_OVERVIEW = 'TYPE_DASHBORAD_TOGGLE_OVERVIEW';

export const ActionToggleMarkets = () => ({
  type: TYPE_DASHBORAD_TOGGLE_MARKETS,
});

export const ActionToggleAlerts = () => ({
  type: TYPE_DASHBORAD_TOGGLE_ALERTS,
});

export const ActionToggleOverview = () => ({
  type: TYPE_DASHBORAD_TOGGLE_OVERVIEW,
});
